//react-redux
import React, { useState } from "react";
import { FiArrowRight } from "react-icons/fi";

//components
import AcuantReactCamera from "../screens/AcuantReactCamera/index";
import Header from "../Common/header";

//img
import IconCardFront from "../../styles/img/iconCardFront.svg";
import IconCardBack from "../../styles/img/iconCardBack.svg";
import IconSelfie from "../../styles/img/iconSelfie.svg";
const Layout = () => {
  const [next, setNext] = useState(false);

  return (
    <>
      <section className="container p-2 w-full">
        <>
          {next ? (
            <AcuantReactCamera />
          ) : (
            <>
              <section className="flex flex-col gap-2 justify-between">
                <Header />

                <div className="">
                  <h2 className="text-2xl text-center my-5">
                    Confirm your identity
                  </h2>
                  <p className="text-sm text-center m-0 p-0">
                    You’ll need to do the following
                  </p>
                </div>

                <section className="bg-white border rounded-lg border-[#DADADA] px-4 py-5">
                  <div className="flex justify-start items-center gap-3 mb-3">
                    <img alt="icon" src={IconCardFront} />
                    <p className="text-sm ">Scan of the front of the ID</p>
                  </div>
                  <div className="flex justify-start items-center gap-3 mb-3">
                    <img alt="icon" src={IconCardBack} />
                    <p className="text-sm">Scan of the back of the ID</p>
                  </div>
                  <div className="flex justify-start items-center gap-3 mb-3">
                    <img alt="icon" src={IconSelfie} />
                    <p className="text-sm">Quick scan of your face</p>
                  </div>
                </section>
                <p className="text-sm text-center m-0 p-0 opacity-80">
                  Version 1.0.0
                </p>
                <div className="absolute inset-x-0 bottom-6 flex justify-center items-center">
                  <button
                    className="w-11/12 text-base text-center font-medium rounded bg-[#6A2ABD] text-white py-3 px-1 hover:opacity-75 shadow-md flex gap-2 justify-center items-center"
                    onClick={() => {
                      setNext(true);
                    }}
                  >
                    Start <FiArrowRight size={20} />
                  </button>
                </div>
              </section>
            </>
          )}
        </>
      </section>
    </>
  );
};

export default Layout;
