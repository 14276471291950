const Error404 = () => {
  return (
    <>
      <section className="flex justify-center items-center min-h-screen flex-col gap-3 container">
        <h1 className="text-red-500 font-bold text-3xl">404</h1>
      </section>
    </>
  );
};

export default Error404;
