//react

import { useLottie } from "lottie-react";
import Loading from "../styles/Lottie/Loader.json";
//component
import Header from "../components/Common/header";
const AnalyzingLoader = ({ loading, text }) => {
    const options = {
        animationData: Loading,
        loop: true,
    };

    const { View } = useLottie(options);

    return (
        <>
            {loading && (
                <div className="w-full h-full">
                    <Header />
                    <section className="bg-white border rounded-lg border-[#DADADA] px-4 w-full py-48 flex flex-col justify-center items-center my-5">
                        <div className="w-2/6 p-0">
                            {View}
                        </div>
                        <h2 className="text-xl my-2 animate-pulse">Analyzing</h2>
                        {text && <p className="text-xs text-center">{text}</p>}
                    </section>
                </div>
            )}
        </>
    );
};
export default AnalyzingLoader;
