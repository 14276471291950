import React from "react";
import ReactDOM from "react-dom/client";
import "../src/styles/index.css";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import * as Sentry from "@sentry/react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Error404 from "./components/screens/Errors/Error404";
import InvitationFail from "./components/screens/Errors/InvitationFail";

const store = configureStore({ reducer: rootReducer });

const root = ReactDOM.createRoot(document.getElementById("root"));

Sentry.init({
  dsn: "https://339875f9edc2c24a4b93451615ccb9e4@o531043.ingest.sentry.io/4505880650383360",
  defaultIntegrations: false,
  tracesSampleRate: 1.0,
  integrations: [new Sentry.Breadcrumbs()],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
  beforeBreadcrumb(breadcrumb, hint) {
    return breadcrumb.category === "ui.click" ? null : breadcrumb;
  },
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="*" element={<Error404 />} />
          <Route path="/invitationFail" element={<InvitationFail />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
