import Logo from "../../styles/img/header.svg";
const Header = () => {
    return (
        <>
            <section className="flex justify-center items-center mt-4">
                <img src={Logo} alt="logo" className="" />
            </section>
        </>
    );
};

export default Header;
