export const ALERTS_POSITION = "bottom-center"
export const ALERTS_POSITION_SELFIE = "top-center"

export const ALERTS_STYLES = {
  duration: 4000,
  style: {
    fontSize: '0.8rem',
    borderRadius: '5px'
  },
  success: {
    style: {
      background: '#213B30',
      color: '#fff',
    }
  },
  error: {
    style: {
      background: 'rgba(0,0,0,0.8)',
      border: "#fff 1px solid",
      color: '#fff',
    }
  },
}

export const ALERTS_STYLES_SELFIE = {
  duration: 4000,
  style: {
    fontSize: '0.8rem',
    borderRadius: '5px'
  },
  success: {
    style: {
      background: '#213B30',
      color: '#fff',
    }
  },
  error: {
    style: {
      background: 'rgba(0,0,0,0.8)',
      border: "#fff 1px solid",
      color: '#fff',
    }
  },
}

