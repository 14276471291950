//css
import "../../src/styles/App.css";

//react-redux
import React, { useEffect, useState } from "react";
import { isMobile, isTablet } from "react-device-detect";
import { MdCancel } from "react-icons/md";
import { store } from "../services/api/store";
import { scanToken } from "../services/api/slices/paladin";
import * as Sentry from "@sentry/react";

//hooks
import RoundLoader from "../hooks/RoundLoader";
//import useScript from "../hooks/useScript";

//components
import Layout from "../components/screens/Layout";
import InvitationFail from "./screens/Errors/InvitationFail";
const App = () => {
  const [loading, setLoading] = useState(false);
  const { dispatch } = store;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const token = urlSearchParams.get("token");
  const [responseScan, setResponseScan] = useState();

  const queryData = async () => {
    setLoading(true);
    var data = { token };

    try {
      const response = await dispatch(scanToken({ data }));
      setResponseScan(response?.payload);
      setLoading(false);
      Sentry.addBreadcrumb({
        message: "Token validated (App)",
        category: "action",
      });
    } catch (error) {
      setLoading(false);
      Sentry.captureMessage("Error validating token (App)", "error");
      Sentry.addBreadcrumb({
        message: "Error validating token (App)",
        category: "action",
        data: {
          token: token,
        },
      });
      Sentry.captureException(error);
    }
  };

  const initialize = () => {
    let callback = {
      onSuccess: function () {
        window.AcuantJavascriptWebSdk.start(
          () => {
            Sentry.addBreadcrumb({
              message: "Worker is ready",
              category: "action",
            });
          }, //no params, void function, called when workers are ready.
          false, //set to true if you want to run one worker at a time. Default to false.
          0 //set to 1 if hosting via cdn, defaults to 0
        );
      },
      onFail: function (code, description) {
        //handle the error
        Sentry.addBreadcrumb({
          message: "Error initializing worker",
          category: "action",
        });
        Sentry.captureException(description);
      },
    };
    let base64Token = btoa(
      process.env.REACT_APP_USER_NAME + ":" + process.env.REACT_APP_PASSWORD
    );
    window.AcuantJavascriptWebSdk.initialize(
      base64Token, //Acuant credentials in base64 (basic auth format id:pass)
      process.env.REACT_APP_ACUANT_ACAS_ENDPOINT, //endpoint for Acuant's ACAS server
      callback, //callback shown below
      0 //set to 1 if hosting via cdn, defaults to 0
    );
  };

  useEffect(() => {
    initialize();
    //loadScript();
    if (isMobile || isTablet) {
      queryData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isMobile || isTablet ? (
        <>
          {loading && (
            <section className="flex justify-center items-center min-h-screen flex-col gap-3 container px-3 py-5 bg-[#F8F7F6] w-full">
              <RoundLoader loading={loading} />
            </section>
          )}
          <section className="flex justify-start items-center flex-col gap-3 container px-3 py-5 bg-[#F8F7F6] w-full">
            {!loading && (
              <>
                {
                  <>
                    {responseScan?.ScanToken?.Success && <Layout />}
                    {responseScan?.error && <InvitationFail />}
                  </>
                }
              </>
            )}
          </section>
        </>
      ) : (
        <div className="flex justify-center items-center min-h-screen flex-col gap-3 container px-3 py-5 bg-[#F8F7F6] w-full">
          <MdCancel size={150} className="text-[#E43122]" />
          <h2 className="text-2xl text-[#E43122]">Error</h2>
          <p className="my-5 text-center text-sm">
            PALIDIN WebID is not available for desktop computers. <br></br>
            Please open the authentication invitation on a mobile device.
          </p>
        </div>
      )}
    </>
  );
};

export default App;
