const ImagePreview = ({ data }) => {
    return (
        <>
            <div className="flex flex-col justify-between items-center gap-5">
                <div className="flex flex-col justify-between items-center gap-2">
                    {data && data?.dpi < 430 && <p className="font-bold text-sm text-center">Image has a low quality. Please retry.</p>}
                    {data && data?.sharpness < 50 && <p className="font-bold text-sm text-center">Image appears blurry. Please retry.</p>}
                    {data && data?.glare < 50 && <p className="font-bold text-sm text-center">Image has glare. Please retry.</p>}
                    {data && data?.image ? (
                        <img
                            className="my-12"
                            style={{ width: "20em" }}
                            src={data?.image?.data}
                            alt=""
                        />
                    ) : (
                        <img
                            style={{ width: "20em" }}
                            src={
                                "https://www.certificate.digital/images/theme/resize/cropping.webp"
                            }
                            alt=""
                        />
                    )}

                </div>
            </div>
        </>
    );
};

export default ImagePreview;
