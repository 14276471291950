import { MdCancel } from "react-icons/md";

const InvitationFail = () => {
  return (
    <>
      <div className="flex justify-center items-center min-h-screen flex-col gap-3 container px-3 py-5 bg-[#F8F7F6] w-full">
        <MdCancel size={150} className="text-[#E43122]" />
        <h2 className="text-2xl text-[#E43122] text-center">Error</h2>
        <p className="my-5 text-center text-sm">
          Something went wrong. The invitation url has already been used or is
          invalid.
        </p>
      </div>
    </>
  );
};

export default InvitationFail;
