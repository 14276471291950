//react/redux
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { store } from "../../services/api/store";
import { scanProcess } from "../../services/api/slices/paladin";
import { IoMdClose } from "react-icons/io";
import { BiSolidFace } from "react-icons/bi";
import * as Sentry from "@sentry/react";

//constants
import {
  ALERTS_STYLES_SELFIE,
  ALERTS_POSITION_SELFIE,
} from "../../constants/alerts";

//hooks
import AnalyzingLoader from "../../hooks/AnalyzingLoader";

//components
import Header from "../Common/header";
import Success from "./Success";

//images
import IconSun from "../../styles/img/iconSun.svg";
import IconEye from "../../styles/img/iconEye.svg";

const CaptureSelfie = ({ instance, responseScan }) => {
  const [selfie, setSelfie] = useState("");
  const [responsePayload, setResponsePayload] = useState();
  const { dispatch } = store;
  const [loading, setLoading] = useState(false);
  const [toastId, setToastId] = useState(null);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const token = urlSearchParams.get("token");

  const openFrontCamara = () => {
    window.AcuantPassiveLiveness.start(
      faceCaptureCallback,
      faceDetectionStates
    );
  };

  const faceDetectionStates = {
    FACE_NOT_FOUND: "FACE NOT FOUND",
    TOO_MANY_FACES: "TOO MANY FACES",
    FACE_ANGLE_TOO_LARGE: "FACE ANGLE TOO LARGE",
    PROBABILITY_TOO_SMALL: "PROBABILITY TOO SMALL",
    FACE_TOO_SMALL: "FACE TOO SMALL",
    FACE_CLOSE_TO_BORDER: "TOO CLOSE TO THE FRAME",
  };

  const faceCaptureCallback = {
    onDetectorInitialized: () => {},
    onDetection: (text) => {},
    onOpened: () => {
      if (toastId === null || toastId === undefined) {
        setToastId(1);
        toast.success((t) => (
          <span>
            Please put your face in the <b> greeen circle</b>{" "}
            <button onClick={() => toast.dismiss(t.id)}>
              <IoMdClose size={15} />
            </button>
          </span>
        ));
      } else {
        toast.dismiss(toastId);
      }
    },
    onClosed: () => {
      toast.dismiss(toastId);
    },
    onError: (error) => {
      Sentry.addBreadcrumb({
        message: "Error capturing selfie (Capture Selfie)",
        category: "action",
      });
      Sentry.captureException(error);
    },
    onPhotoTaken: () => {
      if (toastId === null || toastId === undefined) {
        setToastId(1);
        toast.success((t) => (
          <span>
            Please touch the <b> greeen checkmark </b>{" "}
            <button onClick={() => toast.dismiss(t.id)}>
              <IoMdClose size={15} />
            </button>
          </span>
        ));
      } else {
        toast.dismiss(toastId);
      }
    },
    onPhotoRetake: () => {},
    onCaptured: (base64Image) => {
      setLoading(true);
      let base64Token = btoa(
        process.env.REACT_APP_USER_NAME + ":" + process.env.REACT_APP_PASSWORD
      );
      window.AcuantPassiveLiveness.getLiveness(
        {
          endpoint: process.env.REACT_APP_LIVENESS_ENDPOINT,
          token: base64Token,
          subscriptionId: process.env.REACT_APP_SUBSCRIPTION_ID,
          image: base64Image,
        },
        (result) => {
          if (result) {
            setSelfie(base64Image);
            Sentry.addBreadcrumb({
              message: "Selfie captured",
              category: "action",
              data: {
                token: token,
              },
            });
          }
        }
      );
    },
  };

  const sendData = async () => {
    const data = {
      acuantTransactionId: instance,
      accessToken: responseScan?.ScanToken?.AccessToken,
      invitationId: responseScan?.ScanToken?.InvitationId,
      selfie: selfie,
    };
    setLoading(true);

    try {
      const response = await dispatch(scanProcess({ data }));
      if (response?.payload?.error) {
        toast.error(<span>{response?.payload?.error}</span>);
        setLoading(false);
        Sentry.addBreadcrumb({
          message:
            "Error from Acuant API when sending selfie image (Capture Selfie)",
          category: "action",
          data: {
            acuantTransactionId: instance,
          },
        });
        Sentry.captureException(response?.payload?.error);
      } else {
        setLoading(false);
        setResponsePayload(response?.payload);
        Sentry.addBreadcrumb({
          message: "Image sent to Acuant API (Capture Selfie)",
          category: "action",
          data: { acuantTransactionId: instance },
        });
      }
    } catch (error) {
      setLoading(false);
      Sentry.addBreadcrumb({
        message: "Error processing selfie data (Capture Selfie)",
        category: "action",
        data: { acuantTransactionId: instance },
      });
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    if (selfie !== null && selfie !== "") {
      sendData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selfie]);

  return (
    <>
      {loading ? (
        <section className="flex justify-center items-center absolute inset-0 flex-col gap-3 container px-3 py-5 bg-[#F8F7F6]">
          <AnalyzingLoader
            loading={loading}
            text="Please don’t close the browser.
                    This will take a moment."
          />
        </section>
      ) : (
        <>
          {responsePayload?.ScanProcess?.Success ? (
            <Success />
          ) : (
            <section className="flex flex-col gap-2 justify-between">
              <Header />
              <div className="">
                <h2 className="text-2xl text-center my-5 mb-6">
                  Take a selfie
                </h2>
                <p className="text-sm text-center m-0 p-0">
                  For the best scan results
                </p>
              </div>

              <section className="bg-white border rounded-lg border-[#DADADA] px-4 py-5">
                <div className="flex justify-start items-center gap-3 mb-3">
                  <img alt="icon" src={IconSun} />
                  <p className="text-sm ">Find an area with good lighting</p>
                </div>
                <div className="flex justify-start items-center gap-3 mb-3">
                  <img alt="icon" src={IconEye} />
                  <p className="text-sm">
                    {" "}
                    Make sure your camera is at eye level
                  </p>
                </div>
              </section>

              <div className="flex flex-col gap-4 w-full items-center justify-center">
                <div className="absolute inset-x-0 bottom-6 flex justify-center items-center">
                  <button
                    className="w-11/12 text-base text-center font-medium rounded bg-[#6A2ABD] text-white py-3 px-1 hover:opacity-75 shadow-md flex gap-2 justify-center items-center"
                    onClick={() => openFrontCamara()}
                  >
                    Take a selfie <BiSolidFace size={20} />
                  </button>
                </div>
              </div>
            </section>
          )}
          <div>
            <div
              id="acuant-face-capture-container"
              style={{
                height: "custom-height",
                width: "custom-width",
              }}
            >
              <div id="face-detection-text"></div>
            </div>
          </div>
        </>
      )}

      <Toaster
        position={ALERTS_POSITION_SELFIE}
        reverseOrder={false}
        toastOptions={ALERTS_STYLES_SELFIE}
      />
    </>
  );
};

export default CaptureSelfie;
