//react

import { useLottie } from "lottie-react";
import Loading from "../styles/Lottie/Loader.json";

//component
import Header from "../components/Common/header";
const RoundLoader = ({
  loading,
}) => {
  const options = {
    animationData: Loading,
    loop: true
  };

  const { View } = useLottie(options);

  return (
    <>
      {loading && (
        <div className="flex flex-col gap-5 items-center justify-center">
          <Header />
          <div className="w-3/6 p-0">
            {View}
          </div>
        </div>
      )}
    </>
  );
};
export default RoundLoader;
