import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  faceMatch: {},
  error: null,
  loading: true,
};

export const getFaceMatch = createAsyncThunk(
  "FaceMatch",
  async ({ data }, thunkAPI) => {
    let subscription = process.env.REACT_APP_SUBSCRIPTION_ID;
    const base64Data = data?.imageFront.split(',')[1];
    let body = {
      Settings: {
        SubscriptionId: subscription,
      },
      Data: {
        ImageOne: base64Data,
        ImageTwo: data?.selfie,
      },
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_FRM_ENDPOINT}/api/v1/facematch`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${process.env.REACT_APP_AUTH_TOKEN}`,
            Accept: "application/json",
          },
        }
      );
      if (response.data) {
        return {
          faceMatch: response.data,
        };
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const selfieSlice = createSlice({
  name: "Selfie",
  initialState,
  reducers: {

    reset: (state) => {
      return initialState;
    },

  },

  extraReducers: (builder) => {
    builder.addCase(getFaceMatch.fulfilled, (state, action) => {
      state.faceMatch = action.payload?.faceMatch;
    });
    builder.addCase(getFaceMatch.rejected, (state, action) => {
      state.faceMatch = initialState.faceMatch;
    });
  },
});

export const { reset } = selfieSlice.actions;
