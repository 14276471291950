// Redux Toolkit
import { combineReducers, configureStore } from '@reduxjs/toolkit'

// Redux Persist
import {
    FLUSH,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    REHYDRATE,
    persistReducer,
    persistStore,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { selfieSlice } from './slices/selfie'
import { paladinSlice } from './slices/paladin'
import { assureSlice } from './slices/Assure'

const combinedReducers = combineReducers({
    selfieReducer: selfieSlice.reducer,
    paladinReducer: paladinSlice.reducer,
    assureReducer: assureSlice.reducer,
})

const persistedReducer = persistReducer(
    {
        key: 'root',
        storage,
        whitelist: ['authReducer'],
    },
    combinedReducers
)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
})

export const persistor = persistStore(store)

//export type OurStore = ReturnType<typeof store.getState>
//export type MyThunkDispatch = typeof store.dispatch