//react/redux
import React, { useState } from "react";
import { store } from "../../../services/api/store";
import { postImage } from "../../../services/api/slices/Assure";
import { FiArrowRight } from "react-icons/fi";
import * as Sentry from "@sentry/react";

//components
import CaptureSelfie from "../CaptureSelfie";
import Header from "../../Common/header";

//hooks
import AnalyzingLoader from "../../../hooks/AnalyzingLoader";
import ImagePreview from "../../../hooks/ImagePreview";

//images
import CardFront from "../../../styles/img/cardFront.svg";
import CardBack from "../../../styles/img/cardBack.svg";

const ManualCapture = ({ instance, responseScan }) => {
  const [imageToken, setImageToken] = useState(false);
  const [imageFront, setImageFront] = useState({});
  const [imageBack, setImageBack] = useState({});
  const [openSelfie, setOpenSelfie] = useState(false);
  const [loading, setLoading] = useState(false);
  const { dispatch } = store;
  const [imageType, setImageType] = useState(0);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const token = urlSearchParams.get("token");

  const capturePhoto = () => {
    window.AcuantCamera.startManualCapture(cameraCallback);
  };
  var cameraCallback = {
    onCaptured: (response) => {
      Sentry.addBreadcrumb({
        message: "Image captured (Manual Capture)",
        category: "action",
        data: {
          token: token,
          height: response?.height,
          width: response?.width,
        },
      });
      try {
        if (!response) {
          setLoading(false);
        } else {
          setLoading(true);
        }
      } catch (err) {
        Sentry.addBreadcrumb({
          message: "Error on imaged captured response (Manual Capture)",
          category: "action",
        });
        Sentry.captureException(err);
      }
    },
    onCropped: (response) => {
      Sentry.addBreadcrumb({
        message: "Image cropped (Manual Capture)",
        category: "action",
        data: {
          token: token,
          cardType: response?.cardType,
          dpi: response?.dpi,
          glare: response?.glare,
          moire: response?.moire,
          moireraw: response?.moireraw,
          sharpness: response?.sharpness,
        },
      });
      try {
        if (response) {
          //cropped response;
          if (!imageToken) {
            setLoading(false);
            setImageType(response?.cardType);
            setImageFront(response);
          } else {
            setLoading(false);
            setImageBack(response);
          }
        }
      } catch (err) {
        Sentry.addBreadcrumb({
          message: "Error on cropped image response (Manual Capture)",
          category: "action",
        });
        Sentry.captureException(err);
      }
    },
    onFrameAvailable: (response) => {},
    onError: (error, code) => {
      Sentry.addBreadcrumb({
        message: "Error capturing image (Manual Capture)",
        category: "action",
      });
      Sentry.captureException(error);
    },
  };

  const dataURLToBlob = (image) => {
    let binary = atob(image);
    let array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/jpg" });
  };

  const queryData = async (params) => {
    setLoading(true);
    try {
      const base64Data = params?.image.split(",")[1];
      const data = {
        side: params?.side,
        instance: instance?.instance,
        image: dataURLToBlob(base64Data),
        AcuantApiSubscriptionId:
          responseScan?.ScanToken?.AcuantApiSubscriptionId,
      };

      const postImg = await dispatch(postImage({ data }));
      setLoading(false);
      if (postImg?.payload?.error) {
        setLoading(false);
        window.alert("Error! please contact us");
        Sentry.addBreadcrumb({
          message: "Error posting image to acuant API (Manual Capture)",
          category: "action",
          data: {
            token: token,
            side: params?.side,
            instance: instance?.instance,
          },
        });
        Sentry.captureException(postImg?.payload?.error);
      } else {
        Sentry.addBreadcrumb({
          message: "Image posted to ACUANT API (Manual Capture)",
          category: "action",
          data: {
            token: token,
            side: params?.side,
            instance: instance?.instance,
          },
        });
        if (params?.side === 0 && imageType === 1) {
          setImageToken(true);
        } else {
          setOpenSelfie(true);
        }
      }
    } catch (error) {
      setLoading(false);
      Sentry.addBreadcrumb({
        message:
          "Error processing the image to post into ACUANT API (Manual Capture)",
        category: "action",
        data: {
          token: token,
          side: params?.side,
          instance: instance?.instance,
        },
      });
      Sentry.captureException(error);
    }
  };

  return (
    <>
      {openSelfie ? (
        <>
          <CaptureSelfie
            instance={instance?.instance}
            responseScan={responseScan}
          />
        </>
      ) : (
        <>
          {!imageToken ? (
            <section className="flex flex-col justify-center gap-6 items-center">
              <Header />
              {imageFront?.image && <ImagePreview data={imageFront} />}
              {loading && (
                <section className="flex justify-center items-center absolute inset-0 flex-col gap-3 container px-3 py-5 bg-[#F8F7F6]">
                  <AnalyzingLoader loading={loading} />
                </section>
              )}
              <div>
                {imageFront?.image ? (
                  <>
                    {!loading && (
                      <div className="flex flex-col gap-4 w-full">
                        {imageFront?.dpi < 430 ||
                        imageFront?.sharpness < 50 ||
                        imageFront?.glare < 50 ? (
                          <div className="absolute inset-x-0 bottom-6 flex justify-center items-center">
                            <button
                              className="w-11/12 text-base text-center font-medium rounded bg-[#6A2ABD] text-white py-3 px-1 hover:opacity-75 shadow-md flex gap-2 justify-center items-center"
                              onClick={() => capturePhoto()}
                            >
                              Retry <FiArrowRight size={20} />
                            </button>
                          </div>
                        ) : (
                          <div className="absolute inset-x-0 bottom-6 flex justify-center items-center">
                            <button
                              className="w-11/12 text-base text-center font-medium rounded bg-[#6A2ABD] text-white py-3 px-1 hover:opacity-75 shadow-md flex gap-2 justify-center items-center"
                              onClick={() =>
                                queryData({
                                  side: 0,
                                  image: imageFront?.image?.data,
                                })
                              }
                            >
                              Continue <FiArrowRight size={20} />
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {!loading && (
                      <div className="flex flex-col w-full items-center justify-center">
                        <div className=" flex flex-col justify-center items-center">
                          <h2 className="text-2xl text-center">
                            Take a picture of the front of your ID.
                          </h2>
                          <img
                            src={CardFront}
                            alt="img"
                            className="w-full my-3"
                          />
                        </div>

                        <div className="absolute inset-x-0 bottom-6  flex justify-center items-center">
                          <button
                            className="w-11/12 text-base text-center font-medium rounded bg-[#6A2ABD] text-white py-3 px-1 hover:opacity-75 shadow-md flex gap-2 justify-center items-center"
                            onClick={() => capturePhoto()}
                          >
                            Continue <FiArrowRight size={20} />
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </section>
          ) : (
            <section className="flex flex-col justify-center gap-6 items-center">
              <Header />
              {imageBack?.image && <ImagePreview data={imageBack} />}
              {loading && (
                <section className="flex justify-center items-center absolute inset-0 flex-col gap-3 container px-3 py-5 bg-[#F8F7F6]">
                  <AnalyzingLoader loading={loading} />
                </section>
              )}
              <div>
                {imageBack?.image ? (
                  <>
                    {!loading && (
                      <div className="flex flex-col gap-4 w-full">
                        {imageBack?.dpi < 430 ||
                        imageBack?.sharpness < 50 ||
                        imageBack?.glare < 50 ? (
                          <div className="absolute inset-x-0 bottom-6 flex justify-center items-center">
                            <button
                              className="w-11/12 text-base text-center font-medium rounded bg-[#6A2ABD] text-white py-3 px-1 hover:opacity-75 shadow-md flex gap-2 justify-center items-center"
                              onClick={() => capturePhoto()}
                            >
                              Retry <FiArrowRight size={20} />
                            </button>
                          </div>
                        ) : (
                          <div className="absolute inset-x-0 bottom-6 flex justify-center items-center">
                            <button
                              className="w-11/12 text-base text-center font-medium rounded bg-[#6A2ABD] text-white py-3 px-1 hover:opacity-75 shadow-md flex gap-2 justify-center items-center"
                              onClick={() =>
                                queryData({
                                  side: 1,
                                  image: imageBack?.image?.data,
                                })
                              }
                            >
                              Continue <FiArrowRight size={20} />
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {!loading && (
                      <div className="flex flex-col w-full items-center justify-center">
                        <div className=" flex flex-col justify-center items-center">
                          <h2 className="text-2xl text-center">
                            Take a picture of the back of your ID.
                          </h2>
                          <img
                            src={CardBack}
                            alt="img"
                            className="w-full my-3"
                          />
                        </div>

                        <div className="absolute inset-x-0 bottom-6 flex justify-center items-center">
                          <button
                            className="w-11/12 text-base text-center font-medium rounded bg-[#6A2ABD] text-white py-3 px-1 hover:opacity-75 shadow-md flex gap-2 justify-center items-center"
                            onClick={() => capturePhoto()}
                          >
                            Continue <FiArrowRight size={20} />
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </section>
          )}
        </>
      )}
    </>
  );
};

export default ManualCapture;
